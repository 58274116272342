import { Box, Button, Dialog, DialogContent, TextField, Typography } from "@mui/material"
import { branchApiNm } from "branch/constant/branchApiNm"
import { BoxFC, BoxFR } from "components/BoxCustom"
import PaperComponent from "components/PaperComponent"
import { DialogFooter, DialogHeader } from "components/dialog/DialogHeader"
import { GlobalStateContext } from "contexts/GlobalStateContext"
import dayjs from "dayjs"
import React, { useCallback, useContext, useEffect, useState } from "react"
import { numberFormat } from "utils"
import { salaryDriverColNm } from "./constant"
import { bahtProps } from "utils/bahtProps"
import { blue, green, grey, red } from "@mui/material/colors"
import { initSalaryDialogDetail } from "./initData"
import { addSumRow, getSumColorClassName, sxSumColorClass } from "utils/highlightSumColor"
import DataGridCellExpand from "components/DataGridCellExpand/DataGridCellExpand"
import { salaryDriverDetailColumns } from "./columns"
import { PrintRounded } from "@mui/icons-material"
import { printDriverSalary } from "branch/functions/printDriverSalary"

const DriverSalaryDetail = ({ drvId, salDte, dialogOpen, setDialogOpen }) => {

  const { ax, msData } = useContext(GlobalStateContext)
  const [dialogData, setDialogData] = useState({ ...initSalaryDialogDetail })
  const [dataTable, setDataTable] = useState([{id: 0}])

  const textFieldProps = useCallback((name) => ({
    size: "small",
    label: salaryDriverColNm[name],
    value: numberFormat(+dialogData[name]),
    ...bahtProps,
    sx: { width: 200 },
  }), [dialogData])

  const handlePrint = useCallback(()=>{
    printDriverSalary({dialogData, dataTable, msData})
  }, [msData, dialogData, dataTable])

  useEffect(() => {
    if (dialogOpen) {
      const postData = {
        DrvId: drvId,
        SalDte: dayjs(salDte).format("YYYY-MM-01")
      }
      ax.post(branchApiNm.getSalaryDetail, postData).then(value => {
        if (value.data) {
          const newData = []
          let no = 0;
          for (const data of value.data.PCTrns) {
            no++;
            const result = {
              id: data.PCTrnsId,
              No: no,
              AccNm: data.AccNm,
              Amnt: data.Amnt,
              AddAmnt: data.ChdTrns?.reduce((acc, cur) => acc + +cur.Amnt, 0),
              PCTrnsId: data.PCTrnsId,
              AptTm: data.AptTm,
              Loc: data.Loc,
              JobOrdId: data.JobOrdId,
              SalDte: data.SalDte,
              IsCmpt: data.IsCmpt,
              ContNo: data.ContNo,
              TotalOut: +data.Amnt + (data.ChdTrns?.reduce((acc, cur) => acc + (cur.PCJnlId === 2? +cur.Amnt: 0), 0) || 0),
            }
            let othAmnt = 0;
            let othDscp = new Set()
            let expTotal = 0;
            if (data.Exps) {
              for (const exp of data.Exps) {
                expTotal += +exp.Amnt
                if ([83, 86, 3, 2, 5, 4, 84].includes(exp.ExpId)) {
                  if([2,3].includes(exp.ExpId)) {
                    result['E3'] += +(exp.Amnt || 0)
                  }
                  result[`E${exp.ExpId}`] = +exp.Amnt
                  
                } else {
                  othAmnt += +exp.Amnt
                  othDscp.add(exp.ExpNm)
                }
              }
              if (othAmnt) {
                // console.log("othDscp::", othDscp)  
                result.OthAmnt = othAmnt
                result.OthDscp = [...othDscp].join(', ')
              }
            }
            result.ExpTotal = expTotal
            newData.push(result)
          }

          setDataTable(addSumRow({
            dataTable: newData,
            sumColNms: ["E83", "E86", "E3", "E5", "E4", "E84", "OthAmnt", "ExpTotal", "TotalOut"],
            sumColTotal: "ContNo"
          }))
          // delete value.data.PCTrns
          // for (const trns of value.data.PCTrns) {

          // }

          setDialogData({
            ...value.data,
            SumInc: +(value.data.SalAmnt || 0) + +(value.data.MobAmnt || 0) + +(value.data.HousAmnt || 0)
              + +(value.data.LivAmnt || 0) + +(value.data.BonsAmnt || 0) + +(value.data.OthAmnt || 0),
            SumDeduct: +(value.data.DeLoanAmnt || 0) + +(value.data.DeElecAmnt || 0) + +(value.data.DeSecDepAmnt || 0)
              + +(value.data.DeSSAmnt || 0) + +(value.data.DeOthAmnt || 0),
          })
        }
      })
    } else {
      setDialogData({ ...initSalaryDialogDetail })
      setDataTable([{ id: 0 }])
    }
  }, [dialogOpen, ax, drvId, salDte])

  console.log("dialogData;:", dialogData)
  console.log("dataTable;:", dataTable)
  return (
    <Dialog
      open={dialogOpen}
      PaperComponent={PaperComponent}
      aria-labelledby="draggable-dialog-title"
      fullWidth maxWidth='xl'
    >
      <DialogHeader handleDialogClose={() => setDialogOpen(false)}
        title="รายละเอียดเงินเดือน"
        onDeleteClick={null}
      />
      <DialogContent>
        <BoxFC pt={2} height={750} width="100%">
          <BoxFR width="100%" sx={{ justifyContent: "space-evenly" }}>
            <Typography variant="h6" ><strong>พนักงานขับรถ:</strong> {dialogData.DrvNm}</Typography>
            <Typography variant="h6"><strong>ประจำเดือน:</strong> {dayjs(dialogData.SalDte).format("MMMM YYYY")}</Typography>
          </BoxFR>
          <BoxFR sx={{ justifyContent: "space-evenly", alignItems: "stretch" }}>
            <BoxFC sx={{
              gap: 1, p: 2,
              border: `1px solid ${grey[500]}`,
              borderRadius: 4,
              position: "relative",
            }}>
              <BoxFR>
                <BoxFC sx={{ gap: 1 }}>
                  <TextField {...textFieldProps("SalAmnt")} />
                  <TextField {...textFieldProps("MobAmnt")} />
                  <TextField {...textFieldProps("HousAmnt")} />
                </BoxFC>
                <BoxFC sx={{ gap: 1 }}>
                  <TextField {...textFieldProps("LivAmnt")} />
                  <TextField {...textFieldProps("BonsAmnt")} />
                  <TextField {...textFieldProps("OthAmnt")} label={`อื่นๆ${dialogData.OthDscp ? `(${dialogData.OthDscp})` : ""}`} />
                </BoxFC>
              </BoxFR>
              <Typography sx={{ alignSelf: "flex-end" }} variant="h6" color={green[800]}>
                <strong>รวมรายรับ : </strong>{numberFormat(dialogData.SumInc)} บาท
              </Typography>
              {/* <Typography >{numberFormat(sumInc)}</Typography> */}
              <Typography variant="body"
                sx={{ position: "absolute", top: -15, left: 17, py: 0, px: 1, bgcolor: "white" }}>รายรับ</Typography>
            </BoxFC>
            <BoxFC sx={{
              gap: 1, p: 2,
              border: `1px solid ${grey[500]}`,
              borderRadius: 4,
              position: "relative",
            }}>
              <BoxFR sx={{ alignItems: "stretch" }}>
                <BoxFC sx={{ gap: 1 }}>
                  <TextField {...textFieldProps("DeLoanAmnt")} />
                  <TextField {...textFieldProps("DeElecAmnt")} />
                  <TextField {...textFieldProps("DeSecDepAmnt")} />
                </BoxFC>
                <BoxFC sx={{ gap: 1 }}>
                  <TextField {...textFieldProps("DeSSAmnt")} />
                  <TextField {...textFieldProps("DeOthAmnt")} label={`อื่นๆ${dialogData.OthDscp ? `(${dialogData.OthDscp})` : ""}`} />
                </BoxFC>

              </BoxFR>
              <Typography sx={{ alignSelf: "flex-end" }} variant="h6" color={red[800]}>
                <strong>รวมรายจ่าย : </strong>{numberFormat(dialogData.SumDeduct)} บาท
              </Typography>
              <Typography variant="body"
                sx={{ position: "absolute", top: -15, left: 17, py: 0, px: 1, bgcolor: "white" }}>รายจ่าย</Typography>
            </BoxFC>

            <BoxFC sx={{
              gap: 1, p: 2,
              border: `1px solid ${grey[500]}`,
              borderRadius: 4,
              position: "relative",
            }}>
              <TextField {...textFieldProps("SumInc")} label="รวมรายรับ" sx={{ width: 250 }} />
              <TextField {...textFieldProps("SumDeduct")} value={numberFormat(dialogData.SumDeduct * -1)}
                label="รวมร่ายจ่าย" sx={{ width: 250 }} />
              <TextField {...textFieldProps("LeftAmnt")} sx={{ width: 250 }} />

              <Typography sx={{ alignSelf: "flex-end" }} variant="h6" color={blue[800]}>
                <strong>คงเหลือ : </strong>{numberFormat(dialogData.SumInc - dialogData.SumDeduct + +(dialogData.LeftAmnt || 0))} บาท
              </Typography>
              <Typography variant="body"
                sx={{ position: "absolute", top: -15, left: 17, py: 0, px: 1, bgcolor: "white" }}>สรุปยอด</Typography>
            </BoxFC>
          </BoxFR>
          <BoxFR>
            <Typography variant="h6" >รายละเอียดวิ่งงาน</Typography>
            <Box flex={1} />
            <Button variant="contained" size="small" sx={{width: 200}} onClick={handlePrint}>
              <PrintRounded sx={{mr:1}}/>พิมพ์</Button>
          </BoxFR>
          <Box flex={1} sx={sxSumColorClass} mt={-1}>
            <DataGridCellExpand
              experimentalFeatures={{ rowPinning: true }}
              disableSelectionOnClick
              hideFooter
              rows={dataTable}
              columns={salaryDriverDetailColumns}
              // onCellClick={handleCellClick}
              getRowClassName={getSumColorClassName}
              pinnedRows={{ bottom: [dataTable.find(row => row.id === 0)] }}
            />
          </Box>
        </BoxFC>
      </DialogContent>
      <DialogFooter okText={""}
        handleDialogClose={() => setDialogOpen(false)}
        handleDialogOk={null} />
    </Dialog>
  )
}

export default React.memo(DriverSalaryDetail)