import { Route } from "react-router-dom"
import JobPlanning from "./JobPlaning/JobPlaning"
import TruckIncomeSummary from "./TruckIncomSummary/TruckIncomeSummary"
import JobOrderAllDetail from "./ContainerAllDetail/JobOrderAllDetail"
import JobOrderExpense from "./JobOrderExpense/JobOrderExpense"
import DriverSalary from "./DriverSalary/DriverSalary"
import FuelReportPeriod from "./FuelReportPeriod/FuelReportPeriod"


// export const branchApiNm = {
//   getJobOrderAllDetail: "/branch/getJobOrderAllDetail",
//   getFinJobOrder: "/branch/getFinJobOrder",
//   getInvoice: "/branch/getInvoice",
//   getPrintInvoiceConfig: "/branch/getPrintInvoiceConfig",
//   insertPrintInvoiceConfig: "/branch/insertPrintInvoiceConfig",
//   deletePrintInvoiceConfig: "/branch/deletePrintInvoiceConfig"
// }
export const extraPageName = {
  JobPlaning: "การวางแผนงาน",
  TruckIncomeSummary: "รายงานรายได้แยกตามรถ",
  JobOrderAllDetail: "รายละเอียดงานและค่าใช้จ่าย",
  JobOrderExpense: "ค่าใช้จ่ายงาน",
  DriverSalary: "เงินเดือนพนักงานขับรถ",
  FuelReportPeriod: "รายงานการใช้น้ำมัน",
}
export const extraPageMenu = [
  { page: "JobPlaning", num: "8.1", menuName: extraPageName.JobPlaning, prNum: 8 },
  { page: "TruckIncomeSummary", num: "8.2", menuName: extraPageName.TruckIncomeSummary, prNum: 8, permission: "EXE;FIN"},
  { page: "JobOrderAllDetail", num: "8.3", menuName: extraPageName.JobOrderAllDetail, prNum: 8, permission: "EXE;FIN"},
  { page: "JobOrderExpense", num: "8.4", menuName: extraPageName.JobOrderExpense, prNum: 8, permission: "EXE;FIN"},
  { page: "DriverSalary", num: "8.5", menuName: extraPageName.DriverSalary, prNum: 8, permission: "EXE;FIN"},
  { page: "FuelReportPeriod", num: "8.6", menuName: extraPageName.FuelReportPeriod, prNum: 8},
]

export const extraRoute = (path)=> [
  <Route key="JobPlaning" path={`${path}/JobPlaning`} ><JobPlanning /> </Route>,
  <Route key={"TruckIncomeSummary"} path={`${path}/TruckIncomeSummary`} ><TruckIncomeSummary /> </Route>,
  <Route key={"JobOrderallDetail"} path={`${path}/JobOrderAllDetail`} ><JobOrderAllDetail /> </Route>,
  <Route key={"JobOrderExpense"} path={`${path}/JobOrderExpense`} ><JobOrderExpense /> </Route>,
  <Route key={"DriverSalary"} path={`${path}/DriverSalary`} ><DriverSalary /> </Route>,
  <Route key={"FuelReportPeriod"} path={`${path}/FuelReportPeriod`} ><FuelReportPeriod /> </Route>,

]
