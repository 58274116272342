import { numberFormat } from "utils";
import { salaryDriverColNm } from "./constant";
import { IconButton } from "@mui/material";
import { ArticleRounded } from "@mui/icons-material";
import dayjs from "dayjs";

export const columns = [
  {
    field: 'ShowDetail',
    headerName: '',
    width: 40,
    align: 'center',
    renderCell: (params) => {
      return (
        <IconButton size="small" >
          <ArticleRounded color="primary" />
        </IconButton>
      )
    }
  },
  {
    field: "No",
    headerName: "No.",
    width: 50,
    align: 'center',
  },
  {
    field: "DrvNm",
    headerName: "ชื่อ-นามสกุล",
    width: 180,
  },
  {
    field: "SalAmnt",
    headerName: salaryDriverColNm.SalAmnt,
    width: 100,
    type: "number",
    align: "right",
    valueFormatter: (params) => {
      return params.value ? numberFormat(params.value) : "-";
    },
  },
  {
    field: "MobAmnt",
    headerName: salaryDriverColNm.MobAmnt,
    width: 100,
    type: "number",
    align: "right",
    valueFormatter: (params) => {
      return params.value ? numberFormat(params.value) : "-";
    },
  },
  {
    field: "HousAmnt",
    headerName: salaryDriverColNm.HousAmnt,
    width: 100,
    type: "number",
    align: "right",
    valueFormatter: (params) => {
      return params.value ? numberFormat(params.value) : "-";
    },
  },
  {
    field: "LivAmnt",
    headerName: salaryDriverColNm.LivAmnt,
    width: 100,
    type: "number",
    align: "right",
    valueFormatter: (params) => {
      return params.value ? numberFormat(params.value) : "-";
    },
  },
  {
    field: "BonsAmnt",
    headerName: salaryDriverColNm.BonsAmnt,
    width: 100,
    type: "number",
    align: "right",
    valueFormatter: (params) => {
      return params.value ? numberFormat(params.value) : "-";
    },
  },
  {
    field: "OthAmnt",
    headerName: salaryDriverColNm.OthAmnt,
    width: 100,
    type: "number",
    align: "right",
    valueFormatter: (params) => {
      return params.value ? numberFormat(params.value) : "-";
    },
  },
  {
    field: "OthDscp",
    headerName: salaryDriverColNm.OthDscp,
    width: 100,
  },
  {
    field: "TripAmnt",
    headerName: salaryDriverColNm.TripAmnt,
    width: 100,
    type: "number",
    align: "right",
    valueFormatter: (params) => {
      return params.value ? numberFormat(params.value) : "-";
    },
  },
  {
    field: "AdvAmnt",
    headerName: salaryDriverColNm.AdvAmnt,
    width: 100,
    type: "number",
    align: "right",
    valueFormatter: (params) => {
      return params.value ? numberFormat(params.value) : "-";
    },
  },
  {
    field: "TrnsAmnt",
    headerName: salaryDriverColNm.TrnsAmnt,
    width: 100,
    type: "number",
    align: "right",
    valueFormatter: (params) => {
      return params.value ? numberFormat(params.value) : "-";
    },
  },
  // {
  //   field: "CalLeftAmnt",
  //   headerName: "คำณวน",
  //   width: 100,
  //   type: "number",
  //   align: "right",
  //   valueGetter: ({row:{TripAmnt, AdvAmnt, TrnsAmnt}}) => +(TripAmnt || 0) + +(AdvAmnt || 0) - +(TrnsAmnt || 0) , 
  //   valueFormatter: (params) => {
  //     return params.value ? numberFormat(params.value) : "-";
  //   },
  // },
  {
    field: "LeftAmnt",
    headerName: salaryDriverColNm.LeftAmnt,
    width: 100,
    type: "number",
    align: "right",
    valueFormatter: ({ value }) => {
      // console.log("value", value) 
      return value ? numberFormat(value) : "0.00";
    },
    cellClassName: ({ value, row: { TripAmnt, AdvAmnt, TrnsAmnt } }) =>
      (+(TripAmnt || 0) + +(AdvAmnt || 0) - +(TrnsAmnt || 0)).toFixed(2) === (+value).toFixed(2) ? "" : "col-red"
  },
  {
    field: "TotalInc",
    headerName: salaryDriverColNm.TotalInc,
    width: 100,
    type: "number",
    align: "right",
    valueGetter: ({ row: { SalAmnt, MobAmnt, HousAmnt, LivAmnt, BonsAmnt, OthAmnt, LeftAmnt } }) =>
      +(SalAmnt || 0) + +(MobAmnt || 0) + +(HousAmnt || 0) + +(LivAmnt || 0) + +(BonsAmnt || 0) + +(OthAmnt || 0) + +(LeftAmnt || 0),
    valueFormatter: (params) => {
      return params.value ? numberFormat(params.value) : "-";
    },
  },
  {
    field: "DeSSPrct",
    headerName: salaryDriverColNm.DeSSPrct,
    width: 100,
    type: "number",
    align: "right",
    valueFormatter: (params) => {
      return params.value ? params.value : "-";
    },
  },
  {
    field: "DeSSAmnt",
    headerName: salaryDriverColNm.DeSSAmnt,
    width: 100,
    type: "number",
    align: "right",
    valueFormatter: (params) => {
      return params.value ? numberFormat(params.value) : "-";
    },
  },
  {
    field: "DeLoanAmnt",
    headerName: salaryDriverColNm.DeLoanAmnt,
    width: 100,
    type: "number",
    align: "right",
    valueFormatter: (params) => {
      return params.value ? numberFormat(params.value) : "-";
    },
  },
  {
    field: "DeElecAmnt",
    headerName: salaryDriverColNm.DeElecAmnt,
    width: 100,
    type: "number",
    align: "right",
    valueFormatter: (params) => {
      return params.value ? numberFormat(params.value) : "-";
    },
  },
  {
    field: "DeSecDepAmnt",
    headerName: salaryDriverColNm.DeSecDepAmnt,
    width: 100,
    type: "number",
    align: "right",
    valueFormatter: (params) => {
      return params.value ? numberFormat(params.value) : "-";
    },
  },
  {
    field: "DeEduLoanAmnt",
    headerName: salaryDriverColNm.DeEduLoanAmnt,
    width: 100,
    type: "number",
    align: "right",
    valueFormatter: (params) => {
      return params.value ? numberFormat(params.value) : "-";
    },
  },
  {
    field: "DeTfcFineAmnt",
    headerName: salaryDriverColNm.DeTfcFineAmnt,
    width: 100,
    type: "number",
    align: "right",
    valueFormatter: (params) => {
      return params.value ? numberFormat(params.value) : "-";
    },
  },
  {
    field: "DeOthAmnt",
    headerName: salaryDriverColNm.DeOthAmnt,
    width: 100,
    type: "number",
    align: "right",
    valueFormatter: (params) => {
      return params.value ? numberFormat(params.value) : "-";
    },
  },
  {
    field: "DeOthDscp",
    headerName: salaryDriverColNm.DeOthDscp,
    width: 150,
  },
  {
    field: "TotalDeduc",
    headerName: salaryDriverColNm.TotalDedc,
    width: 100,
    type: "number",
    align: "right",
    valueGetter: ({ row: { DeLoanAmnt, DeElecAmnt, DeSecDepAmnt, DeSSAmnt, DeOthAmnt } }) =>
      +(DeLoanAmnt || 0) + +(DeElecAmnt || 0) + +(DeSecDepAmnt || 0) + +(DeSSAmnt || 0) + +(DeOthAmnt || 0),
    valueFormatter: (params) => {
      return params.value ? numberFormat(params.value) : "-";
    },
  },
  {
    field: "GrndTotal",
    headerName: salaryDriverColNm.GrndTotal,
    width: 100,
    type: "number",
    align: "right",
    valueGetter: ({ row: { SalAmnt, MobAmnt, HousAmnt, LivAmnt, BonsAmnt, OthAmnt, LeftAmnt,
      DeLoanAmnt, DeElecAmnt, DeSecDepAmnt, DeSSAmnt, DeOthAmnt } }) =>
      +(SalAmnt || 0) + +(MobAmnt || 0) + +(HousAmnt || 0) + +(LivAmnt || 0) + +(BonsAmnt || 0) + +(OthAmnt || 0) + +(LeftAmnt || 0)
      - (+(DeLoanAmnt || 0) + +(DeElecAmnt || 0) + +(DeSecDepAmnt || 0) + +(DeSSAmnt || 0) + +(DeOthAmnt || 0)),
    valueFormatter: (params) => {
      return params.value ? numberFormat(params.value) : "-";
    },
  },
  {
    field: "Rmk",
    headerName: "หมายเหตุ",
    flex: 1,
    minWidth: 120,
  },
]

export const dirverSalaryMasterColumns = [
  {
    field: "No",
    headerName: "No.",
    width: 50,
    align: 'center',
  },
  {
    field: "DrvNm",
    headerName: "พนักงานขับรถ",
    width: 230,
  },
  {
    field: "SalAmnt",
    headerName: salaryDriverColNm.SalAmnt,
    width: 125,
    type: "number",
    align: "right",
    valueFormatter: (params) => {
      return params.value ? numberFormat(params.value) : "-";
    },
  },
  {
    field: "MobAmnt",
    headerName: salaryDriverColNm.MobAmnt,
    width: 125,
    type: "number",
    align: "right",
    valueFormatter: (params) => {
      return params.value ? numberFormat(params.value) : "-";
    },
  },
  {
    field: "HousAmnt",
    headerName: salaryDriverColNm.HousAmnt,
    width: 125,
    type: "number",
    align: "right",
    valueFormatter: (params) => {
      return params.value ? numberFormat(params.value) : "-";
    },
  },
  {
    field: "LivAmnt",
    headerName: salaryDriverColNm.LivAmnt,
    width: 125,
    type: "number",
    align: "right",
    valueFormatter: (params) => {
      return params.value ? numberFormat(params.value) : "-";
    },
  },
  {
    field: "DeSSPrct",
    headerName: salaryDriverColNm.DeSSPrct,
    width: 125,
    type: "number",
    align: "right",
    valueFormatter: (params) => {
      return params.value ? params.value : "-";
    },
  },
  {
    field: "DeLoanAmnt",
    headerName: salaryDriverColNm.DeLoanAmnt,
    width: 125,
    type: "number",
    align: "right",
    valueFormatter: (params) => {
      return params.value ? numberFormat(params.value) : "-";
    },
  },
  {
    field: "DeElecAmnt",
    headerName: salaryDriverColNm.DeElecAmnt,
    width: 125,
    type: "number",
    align: "right",
    valueFormatter: (params) => {
      return params.value ? numberFormat(params.value) : "-";
    },
  },
  {
    field: "DeSecDepAmnt",
    headerName: salaryDriverColNm.DeSecDepAmnt,
    width: 125,
    type: "number",
    align: "right",
    valueFormatter: (params) => {
      return params.value ? numberFormat(params.value) : "-";
    },
  },
  {
    field: "DeEduLoanAmnt",
    headerName: salaryDriverColNm.DeEduLoanAmnt,
    width: 125,
    type: "number",
    align: "right",
    valueFormatter: (params) => {
      return params.value ? params.value : "-";
    },
  },
]

export const salaryDriverDetailColumns = [
  {
    field: 'ShowDetail',
    headerName: '',
    width: 60,
    align: 'center',
    renderCell: (params) => {
      return (
        <IconButton size="small" >
          <ArticleRounded color="primary" />
        </IconButton>
      )
    }
  },
  {
    field: "No",
    headerName: "No.",
    width: 50,
    align: 'center',
  },
  {
    field: "AptTm",
    headerName: "วันที่นัด",
    width: 100,
    type: "dateTime",
    valueFormatter: (params) => {
      return params.value ? dayjs(params.value).format("DD/MM HH:mm") : "-";
    },
  },
  {
    field: "ContNo",
    headerName: "หมายเลขตู้",
    width: 120,
  },
  {
    field: "Loc",
    headerName: "สถานที่",
    width: 220,
  },
  {
    field: "E83",
    headerName: "ค่าเที่ยวคนขับ",
    width: 100,
    align: 'right',
    valueFormatter: ({ value }) => value ? numberFormat(value) : '-'
  },
  {
    field: "E86",
    headerName: "ค่าค้างคืน",
    width: 100,
    align: 'right',
    valueFormatter: ({ value }) => value ? numberFormat(value) : '-'
  },
  
  {
    field: "E3",
    headerName: "ค่ายกตุ้",
    width: 100,
    align: 'right',
    valueFormatter: ({ value }) => value ? numberFormat(value) : '-'
  },
  {
    field: "E5",
    headerName: "ค่าผ่านลาน",
    width: 100,
    align: 'right',
    valueFormatter: ({ value }) => value ? numberFormat(value) : '-'
  },
  {
    field: "E4",
    headerName: "ค่าผ่านท่า",
    width: 100,
    align: 'right',
    valueFormatter: ({ value }) => value ? numberFormat(value) : '-'
  },
  {
    field: "E84",
    headerName: "ทางด่วน",
    width: 100,
    align: 'right',
    valueFormatter: ({ value }) => value ? numberFormat(value) : '-'
  },
  {
    field: "OthAmnt",
    headerName: "ค่าอื่นๆ",
    width: 100,
    align: 'right',
    valueFormatter: ({ value }) => value ? numberFormat(value) : '-'
  },
  {
    field: "OthDscp",
    headerName: "รายละเอียดค่าอื่นๆ",
    minWidth: 150,
  },
  {
    field: "ExpTotal",
    headerName: "รวมค่าใช้จ่าย",
    width: 100,
    align: 'right',
    valueFormatter: ({ value }) => value ? numberFormat(value) : '-'
  },
  {
    field: "TotalOut",
    headerName: "โอนล่วงหน้า",
    width: 100,
    align: 'right',
    valueFormatter: ({ value }) => value ? numberFormat(value) : '-'
  },
  {
    field: "Total",
    headerName: "คงเหลือ",
    width: 100,
    align: 'right',
    valueGetter: ({ row }) =>  +(row.ExpTotal || 0) - row.TotalOut,
    valueFormatter: ({ value }) => value ? numberFormat(value) : '0.00',
    cellClassName: ({ value }) => value < 0 ? 'bg-red' : 'bg-green'
  },
]