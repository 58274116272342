

import { jsPDF } from "jspdf";
import "resource/font/THSarabun-normal"
import "resource/font/THSarabun-bold"
import dayjs from "dayjs";
import companyLogo from "branch/resource/company-logo.png"
import { numberFormat, numberFormatNoFlotingPoint } from "utils";

export const printJobOrderExpense = ({ joData, msData, isPrintJobOrders = true }) => {
  const { CompanyData } = msData.serverData
  const pageWidth = 8.3
  const pageHeight = 11.7
  const pageSize = [pageWidth, pageHeight];
  const defaultHeaderSize = 20;
  const comapanyAddrSize = 10;
  const contentSize = 16;
  const startY = 0.4
  const startX = 0.5
  const lineHeight = 0.30
  const lineHeightHeader = 0.25
  const doc = new jsPDF({
    orientation: "portrait",
    unit: "in",
    format: pageSize
  });

  // let numJobOrder = 0
  let pageNo = 0
  // let maxJobOrderFirst = 19
  // const maxJobOrder = 36
  // let startJobOrder = 0
  // do {
  pageNo++
  if (pageNo !== 1) {
    doc.addPage(pageSize, "portrait")
  }
  let yPos = startY
  let xPos = startX
  const img = new Image()
  img.src = companyLogo

  const logoWidth = 0.85
  const logoHeight = 0.85
  try {
    doc.addImage(img, 'png', xPos, yPos, logoWidth, logoHeight)
    xPos += logoWidth + 0.2
  } catch (e) {
    console.log("error::", e)
  }
  doc.setFont("THSarabun", "bold")
  doc.setFontSize(defaultHeaderSize)
  yPos += 0.2
  doc.text(CompanyData.NameEN, xPos, yPos)
  doc.setFont("THSarabun", "normal")
  doc.setFontSize(comapanyAddrSize)
  yPos += lineHeightHeader
  doc.text(CompanyData.AddrEN, xPos, yPos)
  yPos += lineHeightHeader
  doc.text(`Tax ID: ${CompanyData.TaxId}`, xPos, yPos)
  yPos += lineHeightHeader
  doc.text(`Tel.: ${CompanyData.Tel}${CompanyData.Fax && ` Fax: ${CompanyData.Fax}`}`, xPos, yPos)

  yPos += lineHeight

  doc.setFont("THSarabun", "bold")
  doc.setFontSize(contentSize + 2)
  let topRightYPos = startY + 0.2
  doc.text("ค่าใช้จ่ายใบงาน", 6.7, topRightYPos, { align: "center" })
  topRightYPos += lineHeight
  doc.setFontSize(contentSize)
  doc.setFont("THSarabun", "normal")

  doc.text(`ประเภทงาน: ${joData.ShpmTypNm}`, 6.7, topRightYPos, { align: "center" })
  topRightYPos += lineHeight - 0.05
  const aptTm = joData.AptTm ? dayjs(joData.AptTm).startOf('day') : null
  // const maxAptTm = joData.AptTm ? dayjs(joData.MaxAptTm).startOf('day') : null
  // const aptTmTxt = aptTm ? aptTm.isSame(maxAptTm) ? aptTm.format("DD/MM/YYYY") : `${aptTm.format("DD/MM/YYYY")} - ${maxAptTm.format("DD/MM/YYYY")}` : "-"
  doc.text(`วันที่วิ่งงาน:  ${aptTm ? aptTm.format("DD/MM/YYYY") : "-"}`, 6, topRightYPos)

  doc.setFont("THSarabun", "bold")
  doc.setFontSize(contentSize + 3)
  doc.text(`เลขที่ใบงาน: ${joData.JobOrdId}`, pageWidth/2, yPos, { align: "center" })
  doc.setFontSize(contentSize)
  doc.setFont("THSarabun", "normal")
  yPos += lineHeight
  //------------------------------ Convert joData ------------------------------
  const dataTable = [
    { isSecondPage: 1, header: "CUSTOMER :", value: joData.CusSNm },
    { isSecondPage: 1, header: "JOB NO :", value: joData.JobNo },
    { isSecondPage: 1, header: "BOOKING NO :", value: joData.Bkg },
    { isSecondPage: 0, header: "COMMODITY :", value: joData.CmdyTyp },
    { isSecondPage: 0, header: "LOCATION :", value: joData.Loc },
    { isSecondPage: 0, header: "CY :", value: `${joData.TakePlc || "-"} ${joData.TakeArvTm ? ` - Pick up: ${dayjs(joData.TakeArvTm).format("DD/MM/YYYY HH:mm")}` : ""}` },
    { isSecondPage: 0, header: "RETURN :", value: `${joData.RtnPlc || "-"} ${joData.RtnArvTm ? ` - Return: ${dayjs(joData.RtnArvTm).format("DD/MM/YYYY HH:mm")}` : ""}` },
    { isSecondPage: 0, header: "PRICE :", value: joData.UntPr ? numberFormatNoFlotingPoint(+joData.UntPr) : "-" },
    { isSecondPage: 1, header: "VOLUME :", value: joData.ContSizeFull },
    { isSecondPage: 1, header: "CONTAINER NO. :", value: joData.ContNo },
    { isSecondPage: 1, header: "Driver :", value: joData.DrvNm },
  ]
  
  doc.setLineWidth(0.01)
  const startYData = yPos - 0.2
  const headerWidth = 2.1
  const detailWidth = 4.3
  const headerPos = startX + 0.5
  const detailPos = headerPos + headerWidth + 0.1
  doc.setFont("THSarabun", "bold")
  yPos = startYData + lineHeight * 2 / 3
  let yPosLine = startYData
  let numLine = 0
  for (const data of dataTable) {
    if (pageNo !== 1 && data.isSecondPage === 0) continue
    if (numLine !== 0) {
      doc.line(headerPos, yPosLine, headerPos + headerWidth + detailWidth, yPosLine)
    }

    doc.text(data.header, headerPos + 0.2, yPos)
    const lines = doc.splitTextToSize(data.value, detailWidth - 0.3)
    for (const line of lines) {
      numLine++
      doc.text(line, detailPos + 0.2, yPos)
      yPos += lineHeight
      yPosLine += lineHeight
    }
  }
  if (joData.Rcpt && pageNo === 1) {
    const rcpts = joData.Rcpt.split("\n")
    doc.line(headerPos, yPosLine, headerPos + headerWidth + detailWidth, yPosLine)
    doc.text("ที่อยู่ออกใบเสร็จ :", headerPos + 0.2, yPos)
    for (const rcpt of rcpts) {
      const rcptLines = doc.splitTextToSize(rcpt, detailWidth - 0.3)
      for (const line of rcptLines) {
        numLine++
        doc.text(line, detailPos + 0.2, yPos)
        yPos += lineHeight
        yPosLine += lineHeight
      }
    }
    if (rcpts.length === 0) {
      numLine++
      yPos += lineHeight
      yPosLine += lineHeight
    }
  }
  doc.rect(headerPos, startYData, headerWidth + detailWidth, numLine * lineHeight, "S")
  doc.line(headerPos + headerWidth, startYData, headerPos + headerWidth, startYData + (numLine * lineHeight))

  const startExpXpos = headerPos + 0.3
  const noCol = startExpXpos + 0.3
  const contNoSize = contentSize - 1

  doc.setFontSize(contNoSize)
  doc.setFont("THSarabun", "bold")
  yPos += lineHeight / 2
  doc.text("ค่าใช้จ่ายดำเนินงาน", startExpXpos, yPos)
  doc.setFont("THSarabun", "normal")
  const nameCol = noCol + 0.3
  // const amntCol = nameCol + 2
  // const qtyCol = amntCol + 1.5
  const totalTrnsCol = nameCol + 2.5
  const totalExpCol = totalTrnsCol + 1

  // doc.text("ราคา", amntCol + 0.7, yPos, { align: "right" })
  // doc.text(`จำนวน`, qtyCol - 0.1, yPos)
  doc.text("เงินรับ", totalTrnsCol + 0.8, yPos, { align: "right" })
  doc.text("ค่าใช้จ่าย", totalExpCol + 0.8, yPos, { align: "right" })
  yPos += lineHeight
  let no = 0
  let sumTrns = 0
  for (const trns of joData.PCTrns) {
    no++
    doc.text(`${no}.`, noCol, yPos)
    doc.text(`${trns.ExpNm} ${trns.Dscp?`(${trns.Dscp})`: ""}`, nameCol, yPos)
    // doc.text(numberFormat(trns.Amnt), amntCol + 1, yPos, { align: "right" })
    // doc.text(`1`, qtyCol, yPos)
    doc.text(numberFormat(trns.Amnt), totalTrnsCol + 1, yPos, { align: "right" })
    yPos += lineHeight - 0.04
    sumTrns += +(trns.Amnt || 0)
  }
  let sumExp = 0
  const foundTripExp = joData.PCItems.find(itm => itm.ExpId === 83)//ค่าเที่ยวคนขับ
  let pcItems = [...joData.PCItems]
  if(foundTripExp){
    no++
    doc.text(`${no}.`, noCol, yPos)
    doc.text(`(${foundTripExp.IsCost ? "ต้นทุน" : "รายได้"})  ${foundTripExp.ExpNm}`, nameCol, yPos)
    // doc.text(numberFormat(foundTripExp.Amnt), amntCol + 1, yPos, { align: "right" })
    // doc.text(`${foundTripExp.Qty}`, qtyCol, yPos)
    doc.text(numberFormat(foundTripExp.Total), totalExpCol + 1, yPos, { align: "right" })
    yPos += lineHeight - 0.04
    sumExp += +(foundTripExp.Total || 0)
    pcItems = pcItems.filter(itm => itm.ExpId !== 83)
  }

  for (const exp of pcItems) {
    no++
    doc.text(`${no}.`, noCol, yPos)
    doc.text(`(${exp.IsCost ? "ต้นทุน" : "รายได้"})  ${exp.ExpNm}`, nameCol, yPos)
    // doc.text(numberFormat(exp.Amnt), amntCol + 1, yPos, { align: "right" })
    // doc.text(`${exp.Qty}`, qtyCol, yPos)
    doc.text(numberFormat(exp.Total), totalExpCol + 1, yPos, { align: "right" })
    yPos += lineHeight - 0.04
    sumExp += +(exp.Total || 0)
  }
  doc.setFont("THSarabun", "bold")
  doc.text(`รวม`, nameCol, yPos)
  doc.text(numberFormat(sumTrns), totalTrnsCol + 1, yPos, { align: "right" })
  doc.text(numberFormat(sumExp), totalExpCol + 1, yPos, { align: "right" })

  yPos += lineHeight + 0.1

  const total = sumTrns - sumExp
  const totalText = `สรุปรวม : (${total>0?"ทอนคืน":"จ่ายเพิ่ม"})${numberFormat(Math.abs(total))} บาท`
  const textWidth = doc.getTextWidth(totalText)
  const sumTextX = nameCol + 1.5
  doc.text(totalText, sumTextX, yPos)
  doc.rect(sumTextX - 0.15, yPos - 0.25, textWidth + 0.25, lineHeight + 0.1 , "S")

  yPos += lineHeight*2.5
  const signXPos = 6.3
  doc.line(signXPos -1, yPos, signXPos +1, yPos)
  yPos += lineHeight
  doc.text("ผู้ตรวจสอบ", signXPos, yPos, { align: "center" })

  // startJobOrder = numJobOrder
  // } while ((numJobOrder < joData.JobOrders.length) && !isPrintJobOrders)
  doc.output('dataurlnewwindow');
}