import { Box } from '@mui/material';
import { BoxFC, BoxFR } from 'components/BoxCustom';
import DataGridCellExpand from 'components/DataGridCellExpand/DataGridCellExpand';
import React, { useCallback, useState, useContext, useEffect } from 'react';
import { columns } from './columns';
import { GlobalStateContext } from 'contexts/GlobalStateContext';
import DateTimeSelect from 'components/DateTimeSelect';
import SearchButton from 'components/buttons/SearchButton';
import ClearButton from 'components/buttons/ClearButton';
import { initFilterData, clearFilterData } from './initData';
import { addIdForDataGrid } from 'utils';
import { branchApiNm } from 'branch/constant/branchApiNm';


const FuelReportPeriod = () => {
  const { ax } = useContext(GlobalStateContext)
  const [dataTable, setDataTable] = useState([])
  const [filterData, setFilterData] = useState({ ...initFilterData })

  const getData = useCallback((filter) => {
    ax.post(branchApiNm.getFuelPeriodReport, filter).then(value => {
      if (value.data) {
        const result = value.data
        for(const row of result){
          row.FuelTmSt = filter.FuelTmSt
          row.FuelTmEn = filter.FuelTmEn
          let col = 0
          for(const litr of row.Litrs){
            col++
            row[`Litr${col}`] = litr
          }
          row.Rate = row.TotalLitr === 0? 0 : (row.MileTukDiff/row.TotalLitr).toFixed(2)
        }
        setDataTable(addIdForDataGrid(value.data, "LicNm"))
      }
    })
  }, [ax])

  
  useEffect(() => {
    getData({ ...initFilterData })
  }, [getData])

  return (
    <BoxFC height='100%'>
      <BoxFR>
          <Box flex={1} />
          <DateTimeSelect sx={{ width: 180 }} label='เวลาเติมน้ำมัน' start={filterData.FuelTmSt} end={filterData.FuelTmEn}
            setSelectDate={(st, en) => { setFilterData(o => ({ ...o, FuelTmSt: st, FuelTmEn: en })) }}
            getData={() => { getData(filterData) }}
          />
          <SearchButton onClick={(() => getData(filterData))} />
          <ClearButton onClick={(() => setFilterData({ ...clearFilterData }))} />
      </BoxFR>
      <Box height='100%' >
        <DataGridCellExpand
          
          hideFooter
          rows={dataTable}
          columns={columns}
        />
      </Box>
    </BoxFC>
  );
}

export default FuelReportPeriod