import dayjs from "dayjs";

let col15s = []
for(let i = 0; i < 15; i++){
  col15s.push({ 
    field: `Litr${i + 1}`,
    headerName: `ครั้งที่${i + 1}`,
    width: 70,
  })
}
export const columns = [
  {
    field: "No",
    headerName: "No.",
    width: 50,
    align: 'center',
  },
  {
    field: "LicNm",
    headerName: "ทะเบียนรถ",
    width: 100,
  },
  {
    field: "DrvNm",
    headerName: "พนักงานขับรถ",
    width: 180,
  },
  {
    field: "FuelTmSt",
    headerName: "เริ่มรอบ",
    width: 100,
    type: "dateTime",
    valueFormatter: (params) => {
      return params.value ? dayjs(params.value).format("DD/MM/YYYY") : "-";
    },
  },
  {
    field: "FuelTmEn",
    headerName: "จบรอบ",
    width: 100,
    type: "dateTime",
    valueFormatter: (params) => {
      return params.value ? dayjs(params.value).format("DD/MM/YYYY") : "-";
    },
  },
  ...col15s,
  {
    field: "TotalLitr",
    headerName: "รวมจำนวนลิตร",
    width: 100,
  },
  {
    field: "MileTukSt",
    headerName: "ไมล์เริ่มต้น",
    width: 100,
  },
  {
    field: "MileTukEn",
    headerName: "ไมล์สิ้นสุด",
    width: 100,
  },
  {
    field: "MileTukDiff",
    headerName: "ระยะทาง",
    width: 100,
  },
  {
    field: "Rate",
    headerName: "Rate",
    width: 100,
  },
]