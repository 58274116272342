import dayjs from "dayjs"

export const clearFilterData = {
  FuelTmSt: null,
  FuelTmEn: null,
}

export const initFilterData = {
  ...clearFilterData,
  FuelTmSt: dayjs().add(-1, 'month').format("YYYY-MM-26 00:00:00"),
  FuelTmEn: dayjs().format("YYYY-MM-25 23:59:59")
}