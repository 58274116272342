
import { Box, Button, TextField } from '@mui/material';
import { BoxFC, BoxFR } from 'components/BoxCustom';
import DataGridCellExpand from 'components/DataGridCellExpand/DataGridCellExpand';
import React, { useCallback, useState, useContext, useEffect, useMemo } from 'react';
import { GlobalStateContext } from 'contexts/GlobalStateContext';
import DateTimeSelect from 'components/DateTimeSelect';
import SearchButton from 'components/buttons/SearchButton';
import ClearButton from 'components/buttons/ClearButton';
import { initFilterData, clearFilterData } from './initData';
import { branchApiNm } from 'branch/constant/branchApiNm';
import { jobOrderExpenseColumns } from './columns';
import PettyCashDialog from 'pages/Menu1/PettyCash/PettyCashDialog';
import { green, red } from '@mui/material/colors';
import ComboBox from 'components/ComboBox';
import { printJobOrderExpense } from 'branch/functions/printJobOrderExpense';
import { alertError } from 'components/Alert';
import SelectMonthDialog from './SelectMonthDialog';
import { GRID_CHECKBOX_SELECTION_COL_DEF } from '@mui/x-data-grid';
import { DesktopDatePicker } from '@mui/x-date-pickers-pro';
import JobOrderTransferOpenner from 'pages/Menu1/DailyJob/JobOrderTransferOpenner';

let selectedId = null;
let lastFilter = null
let selectedIds = []

const JObOrderExpense = () => {
  const { ax, msData } = useContext(GlobalStateContext)
  const [dataTable, setDataTable] = useState([])
  const [dialogOpen, setDialogOpen] = useState(false)
  const [dialogSelectMonth, setDialogSelectMonthOpen] = useState(false)
  const [filterData, setFilterData] = useState({ ...initFilterData })
  const [selectionModel, setSelectionModel] = useState([])

  
  const monthPickerProp = useMemo(() => ({
    openTo: "month",
    views: ["year", "month"],
    disableMaskedInput: true,
    label: "คิดเงินเดือน",
    inputFormat: "MMMM YYYY",
    value: filterData.SalDte,
    onChange: (newValue) => { setFilterData(o => ({ ...o, SalDte: newValue })) },
    renderInput: (params) => <TextField size="small" {...params} sx={{ width: 160 }} />,

  }), [filterData.SalDte])

  const setAllData = useCallback((datas) => {
    const newData = []
    let no = 0;
    for (const data of datas) {
      no++;
      const result = {
        id: data.PCTrnsId,
        No: no,
        AccNm: data.AccNm,
        Amnt: data.Amnt,
        AddAmnt: data.ChdTrns?.reduce((acc, cur) => acc + (cur.PCJnlId===2? +cur.Amnt: 0), 0),
        OverAmnt: data.ChdTrns?.reduce((acc, cur) => acc + (cur.PCJnlId===100? +cur.Amnt: 0), 0),
        PCTrnsId: data.PCTrnsId,
        AptTm: data.AptTm,
        Loc: data.Loc,
        JobOrdId: data.JobOrdId,
        SalDte: data.SalDte,
        IsCmpt: data.IsCmpt,
        FinDte: data.FinDte
      }
      let othAmnt = 0;
      let othDscp = new Set()
      let expTotal = 0;
      if (data.Exps) {
        for (const exp of data.Exps) {
          expTotal += +exp.Amnt
          if ([83, 3, 5, 4, 84, 95, 82, 86, 107, 89, 91, 96, 93, 94, 92, 100].includes(exp.ExpId)) {
            result[`E${exp.ExpId}`] = +exp.Amnt
          } else {
            othAmnt += +exp.Amnt
            othDscp.add(exp.ExpNm)
          }
        }
        if (othAmnt) {
          // console.log("othDscp::", othDscp)  
          result.OthAmnt = othAmnt
          result.OthDscp = [...othDscp].join(', ')
        }
      }
      result.ExpTotal = expTotal
      newData.push(result)
    }
    setDataTable(newData)
  }, [])

  const getData = useCallback((filter) => {
    ax.post(branchApiNm.getJobOrderExpense, filter).then(value => {
      if (value.data) {
        // setDataTable(addIdForDataGrid(value.data, 'PCTrnsId'))
        lastFilter = filter
        setAllData(value.data)
      }
    })
  }, [ax, setAllData])

  const handleCellClick = useCallback((params) => {
    selectedId = params.id
    if (params.field === 'ShowDetail') {
      setDialogOpen(true)
    } else if (params.field === 'Print') {
      ax.post(branchApiNm.getPrintData, { PCTrnsId: selectedId }).then(value => {
        if (value.data && value.data) {
          printJobOrderExpense({ joData: value.data, msData })
        }
      })
    } else if(params.field === 'SalDte'){
      if(selectionModel.includes(params.id)){
        selectedIds = selectionModel
      } else {
        selectedIds = [params.id]
      }
      setDialogSelectMonthOpen(true)
    }
  }, [ax, msData, selectionModel])

  const handleRowDoubleClick = useCallback((params) => {
    selectedId = params.id
    setDialogOpen(true)
  }, [])

  const onFinish = useCallback((data) => {
    getData(lastFilter)
  }, [getData])

  const handleSetSalDte = useCallback(() => {
    if (selectionModel.length === 0) {
      alertError("กรุณาเลือกข้อมูล")
      return
    }
    selectedIds = selectionModel
    setDialogSelectMonthOpen(true)
  }, [selectionModel])

  const onSelectMonthDialogFinish = useCallback((data) => {
    const postData = {
      PCTrnsIds: selectedIds,
      SalDte: data,
      getArgs: lastFilter
    }
    ax.post(branchApiNm.insertDeleteSalaryPCTrnsaction, postData).then(value => {
      if (value.data) {
        setAllData(value.data)
      }
    })
  }, [ax, setAllData])

  const onJobOrderTrnsferFinish = useCallback(() =>{
    getData(lastFilter)
  }, [getData])

  useEffect(() => {
    getData({ ...initFilterData })
  }, [getData])

  return (
    <BoxFC height='100%'>
      <BoxFR>
        <Button variant="contained" onClick={handleSetSalDte}>กำหนดเดือนคิดค่าเที่ยว</Button>
        
        <JobOrderTransferOpenner onFinish={onJobOrderTrnsferFinish} />

        <Box flex={1} />
        <ComboBox sx={{ width: 150 }} options={msData.driverOnlyCombo} label={"พนักงานขับรถ"}
          selectedId={filterData.DrvId}
          setSelectedId={(id) => setFilterData(o => ({ ...o, DrvId: id }))}
        />
        <DateTimeSelect sx={{ width: 180 }} label='วันนัด' start={filterData.AptTmSt} end={filterData.AptTmEn}
          setSelectDate={(st, en) => { setFilterData(o => ({ ...o, AptTmSt: st, AptTmEn: en })) }}
          getData={() => { getData(filterData) }}
        />
        <DateTimeSelect sx={{ width: 180 }} label='วันที่ลงจบ' start={filterData.FinDteSt} end={filterData.FinDteEn}
          setSelectDate={(st, en) => { setFilterData(o => ({ ...o, FinDteSt: st, FinDteEn: en })) }}
          getData={() => { getData(filterData) }}
        />
        
        <DesktopDatePicker {...monthPickerProp} />

        <SearchButton onClick={(() => getData(filterData))} />
        <ClearButton onClick={(() => setFilterData({ ...clearFilterData }))} />
      </BoxFR>
      <Box height='100%' sx={{
        "& .bg-red": { bgcolor: red[100] },
        "& .bg-green": { bgcolor: green[100] }
      }}>
        <DataGridCellExpand
          hideFooter
          checkboxSelection
          selectionModel={selectionModel}
          onSelectionModelChange={(ids) => {
            setSelectionModel(ids);
          }}
          rows={dataTable}
          columns={jobOrderExpenseColumns}
          onRowDoubleClick={handleRowDoubleClick}
          onCellClick={handleCellClick}
          initialState={{
            pinnedColumns: {
              left: [GRID_CHECKBOX_SELECTION_COL_DEF.field, 'ShowDetail', "IsCmpt", "Print", "No", "AccNm", "AptTm", "JobOrdId", "Loc", "Amnt", "AddAmnt", "TotalOut", "ExpTotal", "Total", "OverAmnt"],
            },
          }}
        />
      </Box>
      <PettyCashDialog
        selectedId={selectedId}
        dialogOpen={dialogOpen}
        setDialogOpen={setDialogOpen}
        onFinish={onFinish} />
      <SelectMonthDialog
        dialogOpen={dialogSelectMonth}
        setDialogOpen={setDialogSelectMonthOpen}
        onFinish={onSelectMonthDialogFinish} />
    </BoxFC>
  );
}

export default JObOrderExpense