import { Box, Button, TextField } from '@mui/material';
import { BoxFC, BoxFR } from 'components/BoxCustom';
import DataGridCellExpand from 'components/DataGridCellExpand/DataGridCellExpand';
import React, { useCallback, useState, useContext, useEffect, useMemo } from 'react';
import { addSumRow, getSumColorClassName, sxSumColorClass } from 'utils/highlightSumColor';
import { columns } from './columns';
import { GlobalStateContext } from 'contexts/GlobalStateContext';
import SearchButton from 'components/buttons/SearchButton';
import ClearButton from 'components/buttons/ClearButton';
import { initFilterData, clearFilterData } from './initData';
import { addIdForDataGrid } from 'utils';
import { DesktopDatePicker } from '@mui/x-date-pickers-pro';
import { branchApiNm } from 'branch/constant/branchApiNm';
import dayjs from 'dayjs';
import DriverSalaryMasterDialog from './DriverSalaryMasterDialog';
import { CloudDownloadRounded } from '@mui/icons-material';
import { alertError } from 'components/Alert';
import { salaryDriverColNm } from './constant';
import TextFieldDialog from 'components/dialog/TextFieldDialog';
import { red } from '@mui/material/colors';
import DriverSalaryDetailDialog from './DriverSalaryDetailDialog';
import ComboBox from 'components/ComboBox';

let selectedRow = null
let selectedField = null
let lastFilter = null

const DriverSalary = () => {
  const { ax, msData } = useContext(GlobalStateContext)
  const [dataTable, setDataTable] = useState([])
  const [selectionModel, setSelectionModel] = useState([])
  const [dialogDiverSalaryMasterOpen, setDialogDiverSalaryMasterOpen] = useState(false)
  const [dialogTextFieldOpen, setDialogTextFieldOpen] = useState(false)
  const [filterData, setFilterData] = useState({ ...initFilterData })
  const [dialogDriverSalaryDetailOpen, setDialogDriverSalaryDetailOpen] = useState(false)

  const monthPickerProp = useMemo(() => ({
    openTo: "month",
    views: ["year", "month"],
    disableMaskedInput: true,
    label: "เดือน-ปี",
    inputFormat: "MMMM YYYY",
    value: filterData.SalDte,
    onChange: (newValue) => { setFilterData(o => ({ ...o, SalDte: newValue })) },
    renderInput: (params) => <TextField size="small" {...params} sx={{ width: 200 }} />,

  }), [filterData.SalDte])

  const modDataTable = useMemo(() => {
    const result = addSumRow({
      dataTable: dataTable,
      sumColNms: ["SalAmnt", "MobAmnt", "HousAmnt", "LivAmnt", "BonsAmnt", "OthAmnt"
        , "DeLoanAmnt", "DeElecAmnt", "DeSecDepAmnt", "DeOthAmnt", "DeTfcFineAmnt", "DeEduLoanAmnt", "DeSSAmnt"
        , "TripAmnt", "AdvAmnt", "TrnsAmnt", "LeftAmnt"],
      sumColTotal: "DrvNm"
    })
    console.log("result::", result)
    return result
  }, [dataTable])

  const getData = useCallback((filter) => {
    const postData = {
      ...filter,
      SalDte: dayjs(filter.SalDte).format("YYYY-MM-01")
    }
    ax.post(branchApiNm.getDriverSalary, postData).then(value => {
      if (value.data) {
        lastFilter = filter
        setDataTable(addIdForDataGrid(value.data, 'DrvId'))
      }
    })
  }, [ax])

  const handleGetDriverMaster = useCallback(() => {
    if (selectionModel.length === 0) {
      alertError("กรุณาเลือกพนักงานขับรถ")
      return
    }
    const postData = {
      DrvIds: selectionModel,
      SalDte: dayjs(filterData.SalDte).format("YYYY-MM-01"),
      getArgs: {
        ...lastFilter,
        SalDte: dayjs(lastFilter.SalDte).format("YYYY-MM-01")
      }
    }

    ax.post(branchApiNm.insertUpdateDrierSalaryFromMaster, postData).then(value => {
      if (value.data) {
        setDataTable(addIdForDataGrid(value.data, 'DrvId'))
      }
    })
  }, [ax, selectionModel, filterData.SalDte])


  const onTextFieldDialogFinish = useCallback((value) => {
    const postData = {
      DrvIds: selectionModel.includes(selectedRow.id) ? selectionModel : [selectedRow.id],
      SalDte: dayjs(filterData.SalDte).format("YYYY-MM-01"),
      ColNm: selectedField,
      ColVal: value === "" ? null : value,
      getArgs: {
        ...lastFilter,
        SalDte: dayjs(lastFilter.SalDte).format("YYYY-MM-01")
      }
    }
    ax.post(branchApiNm.inssertUpdateDriverSalary, postData).then(value => {
      if (value.data) {
        setDataTable(addIdForDataGrid(value.data, 'DrvId'))
      }
    }, [])
  }, [ax, selectionModel, filterData.SalDte])

  const handleCellClick = useCallback((params) => {
    selectedRow = params.row
    selectedField = params.field
    if (["SalAmnt", "MobAmnt", "HousAmnt", "LivAmnt", "BonsAmnt", "OthAmnt", "OthDscp", "Rmk"
      , "DeLoanAmnt", "DeElecAmnt", "DeSecDepAmnt", "DeSSPrct", "DeOthAmnt", "DeOthDscp", "DeTfcFineAmnt"
      , "DeEduLoanAmnt"].includes(params.field)) {
      setDialogTextFieldOpen(true)
    } else if (params.field === "ShowDetail") {
      setDialogDriverSalaryDetailOpen(true)
    }
  }, [])

  console.log("selectedField::", selectedField)
  useEffect(() => {
    getData({ ...initFilterData })
  }, [getData])

  return (
    <BoxFC height='100%'>
      <BoxFR>
        <Button variant='contained' onClick={() => { setDialogDiverSalaryMasterOpen(true) }}>กำหนดค่าเริ่มต้น พขร</Button>
        <Button variant='contained' onClick={handleGetDriverMaster}>
          <CloudDownloadRounded sx={{ mr: 1 }} />ดึงข้อมูลค่าเริ่มต้น
        </Button>
        <Box flex={1} />
        <ComboBox sx={{ width: 150 }} options={msData.driverOnlyCombo} label="พนักงานขับรถ"
          selectedId={filterData.DrvId}
          setSelectedId={(id) => setFilterData(o => ({ ...o, DrvId: id }))}
        />
        <DesktopDatePicker {...monthPickerProp} />
        <SearchButton onClick={(() => getData(filterData))} />
        <ClearButton onClick={(() => setFilterData({ ...clearFilterData }))} />
      </BoxFR>
      <Box height='100%' sx={{ ...sxSumColorClass, "& .col-red": { bgcolor: red[100] } }}>
        <DataGridCellExpand
          experimentalFeatures={{ rowPinning: true }}
          checkboxSelection
          selectionModel={selectionModel}
          onSelectionModelChange={(newSelectionModel) => {
            setSelectionModel(newSelectionModel);
          }}
          hideFooter
          rows={modDataTable}
          columns={columns}
          onCellClick={handleCellClick}
          pinnedRows={{ bottom: [modDataTable.find(row => row.id === 0)] }}
          getRowClassName={getSumColorClassName}

        />
      </Box>
      <DriverSalaryMasterDialog
        dialogOpen={dialogDiverSalaryMasterOpen}
        setDialogOpen={setDialogDiverSalaryMasterOpen} />

      <TextFieldDialog title={`ระบุ ${salaryDriverColNm[selectedField]}`}
        label={["OthDscp", "DeOthDscp", "Rmk"].includes(selectedField) ? "ข้อความ" : "จำนวน"}
        type={["OthDscp", "DeOthDscp", "Rmk"].includes(selectedField) ? "text" : "number"}
        oldData={selectedRow ? selectedRow[selectedField] : null} dialogOpen={dialogTextFieldOpen}
        setDialogOpen={setDialogTextFieldOpen} onFinish={onTextFieldDialogFinish} />
      <DriverSalaryDetailDialog
        dialogOpen={dialogDriverSalaryDetailOpen}
        setDialogOpen={setDialogDriverSalaryDetailOpen}
        drvId={selectedRow?.id}
        salDte={filterData.SalDte} />
    </BoxFC>
  );
}

export default DriverSalary