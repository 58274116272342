import { ArticleRounded, PrintRounded } from "@mui/icons-material"
import { Button, IconButton, Typography } from "@mui/material"
import dayjs from "dayjs"
import { numberFormat } from "utils"

export const jobOrderExpenseColumns = [
  {
    field: "No",
    headerName: "No.",
    width: 50,
    align: 'center',
  },
  {
    field: 'ShowDetail',
    headerName: '',
    width: 60,
    align: 'center',
    renderCell: (params) => {
      return (
        <IconButton size="small" >
          <ArticleRounded color="primary" />
        </IconButton>
      )
    }
  },
  {
    field: 'IsCmpt',
    headerName: '',
    width: 40,
    align: 'center',
    renderCell: (params) => {
      return params.row.IsCmpt ? <Typography color="success">✔</Typography> : ""
    }
  },
  {
    field: 'Print',
    headerName: '',
    width: 40,
    align: 'center',
    renderCell: (params) => {
      return (
        <IconButton size="small">
          <PrintRounded color="primary" />
        </IconButton>
      )
    }
  },
  {
    field: "AccNm",
    headerName: "ชื่อพนักงาน",
    width: 150,
  },
  {
    field: "JobOrdId",
    headerName: "เลขที่ใบงาน",
    width: 90,
  },
  {
    field: "AptTm",
    headerName: "วันที่นัด",
    width: 100,
    type: "dateTime",
    valueFormatter: (params) => {
      return params.value ? dayjs(params.value).format("DD/MM HH:mm") : "-";
    },
  },
  {
    field: "FinDte",
    headerName: "วันที่ลงจบ",
    width: 100,
    type: "dateTime",
    valueFormatter: (params) => {
      return params.value ? dayjs(params.value).format("DD/MM HH:mm") : "-";
    },
  },
  {
    field: "Loc",
    headerName: "สถานที่",
    width: 150,
  },
  {
    field: "Amnt",
    headerName: "สำรองจ่าย",
    width: 100,
    align: 'right',
    valueFormatter: ({ value }) => value ? numberFormat(value) : '-'
  },
  {
    field: "AddAmnt",
    headerName: "เงินเพิ่ม",
    width: 100,
    align: 'right',
    valueFormatter: ({ value }) => value ? numberFormat(value) : '-'
  },
  {
    field: "TotalOut",
    headerName: "รวมเงินรับ",
    width: 100,
    align: 'right',
    valueGetter: ({ row }) => +row.Amnt + +(row.AddAmnt || 0),
    valueFormatter: ({ value }) => value ? numberFormat(value) : '-'
  },
  {
    field: "ExpTotal",
    headerName: "รวมค่าใช้จ่าย",
    width: 100,
    align: 'right',
    valueFormatter: ({ value }) => value ? numberFormat(value) : '-'
  },
  {
    field: "OverAmnt",
    headerName: "เงินเหลือ/เงินเพิ่ม",
    width: 100,
    align: 'right',
    valueFormatter: ({ value }) => value ? numberFormat(value) : '0.00',
    cellClassName: ({ row, value }) => (Math.abs(+value)).toFixed(2) !== (Math.abs(+(row.ExpTotal || 0) - +(row.AddAmnt || 0) - +row.Amnt)).toFixed(2) ? 'bg-red' : ''
  },
  {
    field: "Total",
    headerName: "คชจ.-สำรองจ่าย",
    width: 100,
    align: 'right',
    valueGetter: ({ row }) => +(row.ExpTotal || 0) - +(row.AddAmnt || 0) - +row.Amnt,
    valueFormatter: ({ value }) => value ? numberFormat(value) : '0.00',
    // cellClassName: ({ value }) => value < 0 ? 'bg-red' : 'bg-green'
  },
  {
    field: "SalDte",
    headerName: "คิดเงินดือน",
    width: 100,
    // valueFormatter: ({ value }) => value ? dayjs(value).format("MM/YYYY") : '',
    renderCell: ({ value }) => (
      <Button size="small" variant={value ? "outlined" : "contained"}>
        {value ? dayjs(value).format("MM/YYYY") : "กำหนดเดือน"}
      </Button>
    )
  },
  {
    field: "E83",
    headerName: "ค่าเที่ยวคนขับ",
    width: 100,
    align: 'right',
    valueFormatter: ({ value }) => value ? numberFormat(value) : '-'
  },
  {
    field: "E86",
    headerName: "ค่าค้างคืน",
    width: 100,
    align: 'right',
    valueFormatter: ({ value }) => value ? numberFormat(value) : '-'
  },
  {
    field: "E3",
    headerName: "ค่ายกตุ้เปล่า",
    width: 100,
    align: 'right',
    valueFormatter: ({ value }) => value ? numberFormat(value) : '-'
  },
  {
    field: "E5",
    headerName: "ค่าผ่านลาน",
    width: 100,
    align: 'right',
    valueFormatter: ({ value }) => value ? numberFormat(value) : '-'
  },
  {
    field: "E4",
    headerName: "ค่าผ่านท่า",
    width: 100,
    align: 'right',
    valueFormatter: ({ value }) => value ? numberFormat(value) : '-'
  },
  {
    field: "E84",
    headerName: "ทางด่วน",
    width: 100,
    align: 'right',
    valueFormatter: ({ value }) => value ? numberFormat(value) : '-'
  },
  {
    field: "E95",
    headerName: "ค่าล่วงเวลาฝั่งลาว",
    width: 100,
    align: 'right',
    valueFormatter: ({ value }) => value ? numberFormat(value) : '-'
  },
  {
    field: "E82",
    headerName: "ค่าใช้จ่ายฝั่งลาว",
    width: 100,
    align: 'right',
    valueFormatter: ({ value }) => value ? numberFormat(value) : '-'
  },
  {
    field: "E107",
    headerName: "ค่าล่วงเวลา",
    width: 100,
    align: 'right',
    valueFormatter: ({ value }) => value ? numberFormat(value) : '-'
  },
  {
    field: "E89",
    headerName: "ค่ารถลาก",
    width: 100,
    align: 'right',
    valueFormatter: ({ value }) => value ? numberFormat(value) : '-'
  },
  {
    field: "E91",
    headerName: "ค่าจอดรถฝั่งลาว",
    width: 100,
    align: 'right',
    valueFormatter: ({ value }) => value ? numberFormat(value) : '-'
  },
  {
    field: "E96",
    headerName: "ค่าถ่ายเอกสาร/ปริ้นในท่า",
    width: 100,
    align: 'right',
    valueFormatter: ({ value }) => value ? numberFormat(value) : '-'
  },
  {
    field: "E93",
    headerName: "ค่า ต.ม.ลาว",
    width: 100,
    align: 'right',
    valueFormatter: ({ value }) => value ? numberFormat(value) : '-'
  },
  {
    field: "E94",
    headerName: "ค่าปั๊มพาสปอร์ต",
    width: 100,
    align: 'right',
    valueFormatter: ({ value }) => value ? numberFormat(value) : '-'
  },
  {
    field: "E92",
    headerName: "ค่าพ่นยา/ค่าล้างล้อ",
    width: 100,
    align: 'right',
    valueFormatter: ({ value }) => value ? numberFormat(value) : '-'
  },
  {
    field: "E100",
    headerName: "ค่าธรรมเนียมการโอนเงิน",
    width: 100,
    align: 'right',
    valueFormatter: ({ value }) => value ? numberFormat(value) : '-'
  },

  {
    field: "OthAmnt",
    headerName: "ค่าอื่นๆ",
    width: 100,
    align: 'right',
    valueFormatter: ({ value }) => value ? numberFormat(value) : '-'
  },
  {
    field: "OthDscp",
    headerName: "รายละเอียดค่าอื่นๆ",
    flex: 1,
    minWidth: 120,
  },
]