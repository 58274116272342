import { Box, Button, TextField, Typography } from "@mui/material";
import { BoxFC, BoxFR } from "components/BoxCustom";
import { GlobalStateContext } from "contexts/GlobalStateContext";
import dayjs from "dayjs";
import React, { useCallback, useContext, useEffect } from "react";
import { useMemo } from "react";
import { useState } from "react";
import SearchRoundedIcon from '@mui/icons-material/SearchRounded';
import { addIdForDataGrid } from "utils";
import { columns } from "./columns";
import DataGridCellExpand from "components/DataGridCellExpand/DataGridCellExpand";
import ComboBox from "components/ComboBox";
import { jobOrderColNm } from 'constants/jobOrderConstant';
import DateTimeSelect from 'components/DateTimeSelect/DateTimeSelect';
import DataTableDialog from "./DataTableDialog";
import { invoiceColNm } from "constants/invoiceConstant";
import { branchApiNm } from "branch/constant/branchApiNm";

// const initFilterData = {
//   CusId: null,
//   AptTmSt: dayjs().add(-1, "month").format("YYYY-MM-01 00:00:00"),
//   AptTmEn: dayjs().add(-1, "month").endOf("month").format("YYYY-MM-DD 23:59:59.997")
// }

const initFilterData = {
  CusId: null,
  JobId: null,
  Loc: "",
  AptTmSt: dayjs().format("YYYY-MM-01 00:00:00"),
  AptTmEn: dayjs().format("YYYY-MM-DD 23:59:59.997"),
  InvDteSt: null,
  InvDteEn: null
}
let mode = "INV"
let dialogDataTable = []
const JobOrderAllDetail = () =>{

  const { ax, msData } = useContext(GlobalStateContext)

  const [filterData, setFilterData] = useState(initFilterData)
  const [jobCombo, setJobCombo] = useState(msData.jobCombo)
  const [dataTable, setDataTable] = useState([])
  const [dialogOpen, setDialogOpen] = useState(false)
  
  const getData = useCallback((filterData) => {
    
    ax.post(branchApiNm.getJobOrderAllDetail, filterData).then(value => {
      if (value.data) {
        for(const row of value.data){
          if(row.Exps && row.Exps.length > 0){
            row.Trns = row.Exps.find(exp=>exp.ExpId === 1)?.Total  || 0
            row.LiftF = row.Exps.find(exp=>exp.ExpId === 2)?.Total  || 0
            row.LiftE = row.Exps.find(exp=>exp.ExpId === 3)?.Total  || 0
            row.PortPass = row.Exps.find(exp=>exp.ExpId === 4)?.Total  || 0
            row.DeptPass = row.Exps.find(exp=>exp.ExpId === 5)?.Total  || 0
            row.OthCusRcpt = row.Exps.reduce((prev, cur)=>{
              if(![2,3,4,5].includes(cur.ExpId) && cur.IsOwnRcpt === 0){
                return prev + cur.Total
              } else {
                return prev
              }
            } , 0)
          }
          let othAmnt = 0
          let othDscp = new Set()
          let sumCost = 0
          for(const pi of row.PCItms){
            if ([83, 3, 5, 4, 84, 95, 82, 86, 107, 89, 91, 96, 93, 94, 92, 100].includes(pi.ExpId)) {
              row[`E${pi.ExpId}`] = +pi.Amnt
            } else {
              othAmnt += +pi.Amnt
              othDscp.add(pi.ExpNm)
            }
            sumCost += +pi.Amnt
          }
          row.OthAmnt = othAmnt
          row.OthDscp = [...othDscp].join(", ")
          row.SumCost = sumCost
        }
        setDataTable(addIdForDataGrid(value.data, "JobOrdId"))
      }
    })
  }, [ax])

  const setSelectDate = useCallback((st, en)=>{
    setFilterData(o=>({...o, AptTmSt: st, AptTmEn: en}))
  }, [])

  const setSelectInvDate = useCallback((st, en)=>{
    setFilterData(o=>({...o, InvDteSt: st, InvDteEn: en}))
  }, [])

  const comboProp = useMemo(() => ({
    options: msData.cusCombo,
    label: "ลูกค้า",
    sx: { width: 200},
    selectedId: filterData.CusId,
    setSelectedId: (id) => {
      setFilterData(o=>({...o, CusId: id}))
      setJobCombo(id?msData.jobCombo.filter(item=>item.refId === id): msData.jobCombo)
    }
  }), [filterData.CusId, msData.cusCombo, msData.jobCombo])

  const comboPropJob = useMemo(() => ({
    options: jobCombo,
    label: "งาน",
    sx: { width: 200},
    selectedId: filterData.JobId,
    setSelectedId: (id) => {setFilterData(o=>({...o, JobId: id}))}
  }), [filterData.JobId, jobCombo])
  
  useEffect(()=>{
    getData(initFilterData)
  }, [getData])
  return (
    <BoxFC p={2} height="100%">
      <BoxFR>
        <Typography variant="h6">รายละเอียดใบงาน </Typography>
        <Box flex={1} />
        <DateTimeSelect
          sx={{width:180}}
          label={jobOrderColNm.AptTm}
          start={filterData.AptTmSt}
          end={filterData.AptTmEn}
          setSelectDate={setSelectDate}
          getData={() => { getData(filterData) }}
        />
        <DateTimeSelect
          sx={{width:180}}
          label={invoiceColNm.InvDte}
          start={filterData.InvDteSt}
          end={filterData.InvDteEn}
          setSelectDate={setSelectInvDate}
          getData={() => { getData(filterData) }}
        />
        <ComboBox {...comboProp}/>
        <ComboBox {...comboPropJob}/>
        <TextField size="small" value={filterData.Loc} label="สถานที่" sx={{width: 150}}
          onChange={(e) => setFilterData(o=>({...o, Loc: e.target.value}))} />
        <Button variant="contained" onClick={() => getData(filterData)}>
          <SearchRoundedIcon mr={1}/> ค้นหา
        </Button>
        {/* <Box flex={1}/>
        <Button variant="contained" onClick={handleShowDialogInv}>จัดเรียงข้อมูล แจ้งหนี้</Button>
        <Button variant="contained" onClick={handleShowDialogBN}>จัดเรียงข้อมูล วางบิล</Button> */}
      </BoxFR>
      <Box width="100%" flexGrow={1} >
        <DataGridCellExpand
          disableSelectionOnClick={false}
          hideFooter
          rows={dataTable}
          columns={columns} />
      </Box>
      <DataTableDialog 
      dialogOpen={dialogOpen} 
      setDialogOpen={setDialogOpen}
      mode={mode}
      dataTable={dialogDataTable}/>
    </BoxFC>
  )
}

export default JobOrderAllDetail